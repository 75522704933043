import { graphql } from "gatsby"
import React from "react"
import Img from "gatsby-image"
import tw from "twin.macro"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Layout from "../components/layout"
import SEO from "../components/seo"
import makeCarousel from "react-reveal/makeCarousel"
import Fade from "react-reveal/Fade"
import styled from "@emotion/styled"
import FormatText from "../components/wysiwyg"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import { BsChevronBarDown } from "react-icons/bs"


export const pageQuery = graphql`
  query ContactQuery($locale: String) {
    socials: allContentfulSocials {
      edges {
        node {
          title
          url
        }
      }
    }
    about: allContentfulAbout(
      filter: { slug: { eq: "licencia" }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          title
          aboutBio {
            json
          }
          id
        }
      }
    }
    session: allContentfulSocials(
      filter: { slug: { eq: "ask-session" }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          id
          title
          url
        }
      }
    }
    slider: allContentfulSliders(
      filter: { slug: { eq: "contacto" }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          id
          images {
            id
            title
            fluid(maxWidth: 2000) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const CarouselUI = ({ children }) => <Container>{children}</Container>
const Carousel = makeCarousel(CarouselUI)

const ContactPage = ({ data }) => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: "see_contact" })}/>
      <div className="pt-16">
        {data.slider.edges.map((slider, i) => (
          <Carousel
            defaultWait={3000}
            maxTurns={100} /*wait for 1000 milliseconds*/
          >
            {slider.node.images.map((item, i) => (
              <Fade key={item.id}>
                <Img
                  title={`@Sol Etchegaray - ${item.title}`}
                  fluid={item.fluid}
                  alt={`@Sol Etchegaray - ${item.title}`}
                />
              </Fade>
            ))}
          </Carousel>
        ))}
      </div>

      <div tw="relative z-50 w-full max-w-6xl px-6 pt-12 mx-auto -mt-64 bg-black md:pt-0 md:px-12">
        <div tw="flex flex-col items-center text-left md:flex-row">
          <h1 tw=" text-2xl flex justify-between text-white text-left w-full md:w-64 md:mr-0">
            <FormattedMessage id="see_contact" />
            <Fade delay={1300} bottom>
              <AnchorLink
                className="inline-block mt-1 text-3xl md:hidden"
                href="#session"
                title="ir a la biografía"
              >
                <BsChevronBarDown />
              </AnchorLink>
            </Fade>
          </h1>
          <div  tw="flex flex-col items-start justify-start flex-1 w-full max-w-4xl pt-6 mt-6 md:items-baseline md:pl-12 md:flex-row">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.instagram.com/etchegaray_sol/"
              tw="flex flex-col items-start justify-center mb-12 font-sans text-lg md:my-0 md:mr-12 hover:text-yellow-700"
            >
              @etchegaray_sol
              <span tw="text-sm underline opacity-75">Instagram</span>{" "}
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              tw="flex flex-col mb-12 font-sans text-lg md:pl-12 hover:text-yellow-700"
              href="mailto:solchue.99@gmail.com"
            >
              solchue.99@gmail.com
              <span tw="text-sm underline opacity-75">mail</span>
            </a>
            <AnchorLink
              tw="flex-col hidden font-sans text-lg leading-6 w-full text-yellow-700 text-right underline md:flex md:pl-12"
              href="#licencia"
            >
              <FormattedMessage id="license" />
            </AnchorLink>
          </div>
        </div>
        <div id="session" tw="flex flex-col md:justify-start justify-center md:items-center items-start text-left md:flex-row border-t pt-12 border-yellow-700">
          <h1 tw="text-2xl text-white pt-12 md:pt-0 text-left md:mr-0">
            {data.session.edges.map(({ node }) => {
              return <>{node.title}</>
            })}
          </h1>
          {data.session.edges.map(({ node }) => {
            return (
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={node.url}
                tw="inline-block px-4 py-2 mt-2 md:ml-24 mr-2 text-xl font-bold text-gray-800 hover:text-yellow-900 uppercase bg-white"
              >
                <FormattedMessage id="submit_form" />
              </a>
            )
          })}
        </div>

        <LicenseText>
          <div tw="max-w-6xl text-white border-t border-b pb-8 border-yellow-700">
            {data.about.edges.map(({ node }) => {
              return (
                <div id={node.id} tw="pt-6 flex flex-col md:flex-row">
                  <div className="flex w-64">
                    <h2 tw="pb-2 text-2xl text-left text-white mr-6">
                      {node.title}
                    </h2>
                  </div>
                  <div id="licencia" tw="w-full md:pl-20 pt-2">
                    <FormatText FormatText={node.aboutBio} />
                  </div>
                </div>
              )
            })}
          </div>
        </LicenseText>
      </div>
    </Layout>
  )
}
export default ContactPage

const Container = styled.div`
  ${tw`relative z-10 flex items-center justify-center w-full mx-auto overflow-hidden text-base bg-black md:block `}
  height: 100vh;

  .gatsby-image-wrapper {
    ${tw`h-screen `}
  }

  img {
    ${tw`object-cover w-full h-screen md:h-auto`}
  }
`

const LicenseText = styled.div`
  ${tw`pt-12 `}
  h1,h2,h3,h4,h5,h6 {
    ${tw`my-6 text-white`}
  }

  h3 {
    ${tw`text-xl`}
  }

  ul {
    li {
      ${tw`pl-2 ml-5 list-disc`}

      p {
        ${tw`mb-1`}
      }
    }
  }
`
